.head-font {
  @include is-head-font;
}

.text-secondary-400 {
  color: $secondary-400;
}

.text-secondary-600 {
  color: $secondary-600;
}

.text-secondary-700 {
  color: $secondary-700;
}

.text-secondary-800 {
  color: $secondary-800;
}

.text-gray {
  color: $gray-color;
}

.border-disable {
  border-color: $form-disable-bg;
}

.font-variant-nums {
  font-variant-numeric: tabular-nums;
}

sub {
  vertical-align: sub !important;
}

.link-color {
  color: $secondary-color;

  &:hover {
    color: $primary-500;
  }
}